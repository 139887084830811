import { Flex, Heading } from "@chakra-ui/react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";
import { Main } from "../components/Main";
import { MarkdownContainer } from "../components/MarkdownContainer";
import { HeaderBar } from "../components/HeaderBar";

const PageTemplate = ({ data }) => {
  return (
    <Layout>
      <Header>
        <HeaderBar />
      </Header>
      <Main pb="16" justifyContent="flex-start" alignItems="center">
        <Flex
          flexDirection="column"
          width="100%"
          maxWidth={["100%", null, null, "120ch"]}
        >
          <Heading
            color="delta"
            mt="8"
            mx="6"
            fontSize="2xl"
            fontWeight="semibold"
            as="h2"
          >
            {data.mdx.frontmatter.title}
          </Heading>
          <MarkdownContainer>
            <MDXRenderer margin="4">{data.mdx.body}</MDXRenderer>
          </MarkdownContainer>
        </Flex>
      </Main>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/content/pages/" }) {
      frontmatter {
        title
      }
      body
    }
  }
`;

export default PageTemplate;
