import { Box, theme } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const MarkdownContainer = styled(Box)`
  padding: ${theme.space[4]};
  color: ${theme.colors.blackAlpha[700]};
  p {
    padding: 0.5rem;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    cursor: pointer;
  }
  ol,
  ul {
    margin-left: 2rem;
  }
`;
